/**
 * Created by Donskov Maksim on 19.12.2016.
 * contact E-mail: senator92@bk.ru
 */
$(function () {
    // wow анимация
    var wow = new WOW();
    wow.init();

    // скролл к якорю
    $(".js__scroll_to").on("click", function (event) {
        event.preventDefault();
        var id = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 500);
    });

    // основной слайдер
    $('.js__m_slider').slick({
        arrows: false,
        dots: true,
        appendDots: '.js__m_slider_dots',
        infinite: false,
        fade: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true
    });

    // слайдер детальной страницы
    $('.js__detail_slider').slick({
        arrows: true,
        dots: false,
        prevArrow: '.detail_slider__arrow--prev',
        nextArrow: '.detail_slider__arrow--next',
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        fade: true,
        speed: 300,
        adaptiveHeight: true
    });

    // Mobile menu
    $('.header__mobile_menu').on('click', function () {
        var button = $(this);
        if ($('.header__nav').css('display') == 'none') {
            $('.header').addClass('header--opend');
            $('.header__nav').addClass('header__nav--opend');
            button.addClass('header__mobile_menu--opend');
        } else {
            $('.header').removeClass('header--opend');
            $('.header__nav').removeClass('header__nav--opend');
            button.removeClass('header__mobile_menu--opend');
        }
    });

    // маска телефона
    $(document).on('click', 'input[data-tel=true]', function () {
        $(this).inputmask("+7 (999) 999-99-99", {showMaskOnHover: false});
    });

    // открытие всплывающего окна
    $(document).on('click', '[data-modal-popup]', function (e) {
        e.preventDefault();
        var button = $(this),
            buttonId = button.data('modal-popup');
        $(buttonId).arcticmodal({
            overlay: {
                css: {
                    backgroundColor: 'rgba(0,0,0,.8)',
                    opacity: 1
                }
            }
        });
    });

    // пример вызова popup через ajax
    $(document).on('click', '[data-modal]', function (e) {
        e.preventDefault();
        var button = $(this),
            modalTitle = button.data('modal-title'),
            formId = button.data('modal-form-id'),
            formTemplate = button.data('modal-form-template');
        $.arcticmodal({
            overlay: {
                css: {
                    backgroundColor: 'rgba(0,0,0,.8)',
                    opacity: 1
                }
            },
            type: 'ajax',
            url: '/ajax/form.php?modalTitle='+modalTitle+'&formId='+formId+'&formTemplate='+formTemplate
        });
    });

    // спойлер
    $(document).on('click', '.js__spoiler_button', function (e) {
        e.preventDefault();
        var content = $('.spoiler__content');
        if(content.hasClass('spoiler__content--open')){
            $(this).removeClass('spoiler__button--open');
            content.removeClass('spoiler__content--open');
        } else {
            content.addClass('spoiler__content--open');
            $(this).addClass('spoiler__button--open');
        }
    });

});
$(window).on('load', function (e) {
    scrollHeader();
    paddingHeader();
    popularSlider();
    scrollUp('.footer__scroll_top', 200);
});
$(window).on('resize', function (e) {
    scrollHeader();
    paddingHeader();
    popularSlider();
});
$(window).on('scroll', function (e) {
    scrollHeader();
    paddingHeader();
});
// отступ высоты шапки
function paddingHeader() {
    $('.main').css('padding-top',$('.inner_page .header').outerHeight());
}
// добавление класса шапки при скроле
function scrollHeader() {
    var header = $('.header');
    if($(window).scrollTop() > 0){
        header.addClass('header--scroll');
    } else {
        header.removeClass('header--scroll');
    }
}
var widthWindow = false;
function popularSlider() {

    if($('body').width() <= 768 && !widthWindow){
        widthWindow = true;

        // слайдеры в компетенциях
        $('.js__products').each(function (i) {
            var parent = $(this);
            parent.addClass('js__products--'+i);
            $('.products__list',parent).slick({
                speed: 900,
                dots: false,
                prevArrow: '.js__products--'+i+' .products__arrow--prev',
                nextArrow: '.js__products--'+i+' .products__arrow--next',
                slidesToShow: 1,
                slidesToScroll: 1
            });
        });
    } else if($('body').width() > 768 && widthWindow) {
        widthWindow = false;

        $('.products__list').slick('unslick');
    }
}
function scrollUp(selector, top) {
    var reg = /[.#]+(.*)/,
        selectorName = reg.exec(selector)[1];

    $(window).on('scroll', function (e) {
        if($(window).scrollTop() > top) {
            $(selector).removeClass(selectorName+'--hidden').addClass(selectorName+'--visible');
        } else {
            $(selector).removeClass(selectorName+'--visible').addClass(selectorName+'--hidden');
        }
    });
}